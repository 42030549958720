/* eslint-disable import/prefer-default-export */
// import { useRouter } from '@/@core/utils'
import abilityObj from '@/plugins/acl/ability'
import router from '@/router'
import axios from '@axios'

const requestPushNotificationToken = () => {
  if (!window.ReactNativeWebView) {
    return
  }

  const data = { type: 'REQUEST_NOTIFICATION_TOKEN' }
  window.ReactNativeWebView.postMessage(JSON.stringify(data))
}

export function authenticate(authPromise, errorHandler, nextPageObj = { name: 'manage-events' }) {
  authPromise
    .then(response => {
      const { accessToken } = response.data
      localStorage.setItem('accessToken', accessToken)

      return response
    })
    .then(() => {
      axios.get('auth/me').then(response => {
        const { email, fullName } = response.data
        const user = {
          id: 1,
          fullName,
          username: email,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/1.png'),
          email,
          role: 'admin',
          ability: [
            {
              action: 'manage',
              subject: 'all',
            },
          ],
        }

        const { ability: userAbility } = user
        abilityObj.update(userAbility)

        localStorage.setItem('userAbility', JSON.stringify(userAbility))
        delete user.ability
        localStorage.setItem('userData', JSON.stringify(user))
        router.push(nextPageObj)
        requestPushNotificationToken()
      })
    })
    .catch(error => {
      console.error('Oops, Unable to login!')
      console.log('error :>> ', error.response)
      errorHandler(error.response.data.message)
    })
}
