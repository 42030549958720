<template>
  <v-card>
    <v-card-title
      dir="auto"
      class="font-weight-bold"
    >
      {{ $t('terms.terms_title') }}
    </v-card-title>
    <perfect-scrollbar
      :options="perfectScrollbarOptions"
      class="scrollBarRecentDecision"
    >
      <v-card-text>
        <div
          v-for="(intro, index) in $t('terms.introduction')"
          :key="index"
          dir="auto"
          class="terms pb-0 mb-0"
        >
          <p
            class="terms"
            v-html="$t(intro.content)"
          >
          </p>
        </div>
      </v-card-text>
      <v-card-text>
        <div
          v-for="(section, index) in $t('terms.sections')"
          :key="index"
          dir="auto"
          class="terms pb-0 mb-0"
        >
          <p
            class="terms"
            v-html="$t(section.title)"
          >
          </p>
          <p
            class="terms"
            v-html="$t(section.content)"
          >
          </p>
        </div>
      </v-card-text>
    </perfect-scrollbar>
  </v-card>
</template>
<script>
import { i18n } from '@/plugins/i18n/index'
import { ref } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    PerfectScrollbar,
  },
  setup() {
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const activeSection = ref(0)

    return {
      activeSection,
      i18n,
      perfectScrollbarOptions,
    }
  },
}
</script>
<style>
.v-application p {
  margin-bottom: 6px;
}

.content-item {
  display: inline-flex;
  align-items: flex-start;
}

.number {
  margin-right: 0px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 0px;
}
</style>
<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';
.scrollBarRecentDecision {
  max-height: calc(var(--vh, 1vh) * 50);
}
</style>
