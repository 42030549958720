<template>
  <div class="auth-wrapper auth-v2">
    <v-btn
      elevation="0"
      color="transparent"
      style="position: absolute; z-index:1;"
      class="mt-2"
    >
      <AppBarI18n></AppBarI18n>
    </v-btn>

    <a
      v-if="$vuetify.breakpoint.mdAndDown"
      href="https://www.anita-lee.com"
      target="_blank"
    >
      <div
        dir="ltr"
        class="d-flex pt-2"
        style="position: absolute;"
        :style="$vuetify.rtl ? 'left:15px' : 'right: 15px'"
      >
        <v-img
          :src="appLogo"
          alt="logo"
          contain
          eager
          max-height="35px"
          max-width="35px"
        ></v-img>

        <v-img
          :src="appName"
          alt="name"
          contain
          eager
          max-height="40px"
          max-width="120px"
          class="mt-2"
        ></v-img>
      </div>
    </a>

    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="462"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/not-authorized.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center justify-center auth-bg pb-0 px-0"
        >
          <!-- brand logo -->
          <a
            v-if="!$vuetify.breakpoint.mdAndDown"
            href="https://www.anita-lee.com"
            target="_blank"
          >
            <div
              dir="ltr"
              class="brand-logo d-flex align-center justify-center"
            >
              <v-img
                :src="appLogo"
                alt="logo"
                contain
                eager
                max-height="35px"
                max-width="35px"
              ></v-img>

              <v-img
                :src="appName"
                alt="name"
                contain
                eager
                max-height="40px"
                max-width="120px"
              ></v-img>
            </div>
          </a>

          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto mt-6 pt-6"
            >
              <v-card flat>
                <v-card-text class="text-center">
                  <p
                    class="font-weight-semibold mb-0"
                    :class="$vuetify.breakpoint.mdAndDown? 'text-xl': 'text-2xl'"
                  >
                    {{ $t('WelcomeToAnitaLee') }}
                  </p>
                  <p class="mb-0">
                    <!-- {{ $t('HappyToBePartOfYourEvent') }} -->
                    {{ $t('PleaseSignUp') }}
                  </p>
                </v-card-text>

                <v-card-text class="text-center">
                  <!-- social links -->
                  <v-card-actions class="d-flex justify-center">
                    <google-auth></google-auth>
                  </v-card-actions>
                  <!-- divider -->
                  <v-card-text class="d-flex align-center mt-0">
                    <v-divider></v-divider>
                    <span class="mx-5">
                      {{ $t('Or') }}
                    </span>
                    <v-divider></v-divider>
                  </v-card-text>

                  <div>
                    <!-- alert if account exist -->
                    <div
                      v-if="accountExist"
                      class="red--text text-l"
                    >
                      {{ $t('AccountExist') }}
                    </div>
                  </div>

                  <!-- login form -->
                  <v-card-text>
                    <v-form
                      ref="registerForm"
                      @submit.prevent="handleFormSubmit"
                    >
                      <v-text-field
                        v-model="username"
                        outlined
                        :label="$t('fullName')"
                        :error-messages="errorMessages.username"
                        :rules="[validators.required, validators.minUserFullName]"
                        :placeholder="$t('fullName')"
                        class="mb-4"
                        hide-details="auto"
                        dense
                      ></v-text-field>

                      <v-text-field
                        v-model="email"
                        outlined
                        :error-messages="errorMessages.email"
                        :rules="[validators.required, validators.emailValidator]"
                        :label="$t('Email')"
                        :placeholder="$t('Email')"
                        hide-details="auto"
                        class="mb-4"
                        dense
                      ></v-text-field>
                      <v-tooltip
                        v-model="showTooltip"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="password"
                            outlined
                            :type="isPasswordVisible ? 'text' : 'password'"
                            :label="$t('Password')"
                            :error-messages="errorMessages.password"
                            :placeholder="$t('Password')"
                            :rules="[validators.required, validators.passwordValidator]"
                            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                            hide-details="auto"
                            class="mb-2"
                            dense
                            v-bind="attrs"
                            @click:append="isPasswordVisible = !isPasswordVisible"
                            @focus="showTooltip = true"
                            @blur="showTooltip = false"
                            @input="keepTooltipOpen"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <span>
                          <div
                            v-for="(item, index) in $t('PasswordGuide')"
                            :key="index"
                            class="d-block mb-1"
                          >
                            <div class="d-flex">
                              <li>
                              </li>
                              <span>
                                {{ item }}
                              </span>
                            </div>
                          </div>
                        </span>
                      </v-tooltip>

                      <!-- alert if not confirm the terms -->
                      <div
                        v-if="termsMsg"
                        class="red--text mx-2"
                        style="text-align-last: start;"
                      >
                        {{ $t('requiredTerms') }}
                      </div>
                      <v-checkbox
                        v-model="termsAgree"
                        hide-details
                        class="mt-0"
                        @change="termsAgree ? termsMsg = false : ''"
                      >
                        <template #label>
                          <div class="d-flex align-center flex-wrap">
                            <span class="me-1">
                              {{ $t('IAgree') }}
                            </span>
                            <a
                              href="javascript:void(0)"
                              @click="termsDialogVisible = true"
                            >
                              {{ $t('Terms') }}
                            </a>
                            <span class="mx-1 primary--text">&</span>
                            <a
                              href="javascript:void(0)"
                              @click="privacyDialogVisible = true"
                            >
                              {{ $t('PrivacyTerms') }}
                            </a>

                            <v-dialog
                              v-model="termsDialogVisible"
                              persistent
                              max-width="500px"
                            >
                              <v-card
                                flat
                              >
                                <Terms></Terms>
                                <v-card-actions class="pa-5 align-center justify-center">
                                  <v-btn
                                    color="primary"
                                    outlined
                                    @click="termsDialogVisible = false"
                                  >
                                    {{ $t('Ok') }}
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="privacyDialogVisible"
                              persistent
                              max-width="500px"
                            >
                              <v-card
                                flat
                              >
                                <PrivacyTerms></PrivacyTerms>
                                <v-card-actions class="pa-5 align-center justify-center">
                                  <v-btn
                                    color="primary"
                                    outlined
                                    @click="privacyDialogVisible = false"
                                  >
                                    {{ $t('Ok') }}
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </div>
                        </template>
                      </v-checkbox>

                      <v-btn
                        block
                        color="primary"
                        type="submit"
                        class="mt-6"
                      >
                        {{ $t('Register') }}
                      </v-btn>
                    </v-form>
                  </v-card-text>

                  <!-- create new account  -->
                  <v-card-text class="d-flex align-center justify-center flex-wrap mt-2 text-base font-weight-semibold">
                    <p class="mb-0 me-2">
                      {{ $t('AlreadyHaveAccount') }}
                    </p>
                    <router-link :to="{name:'auth-login'}">
                      {{ $t('Login') }}
                    </router-link>
                  </v-card-text>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline, import/no-cycle
import PrivacyTerms from '@/components/Common/PrivacyTerms.vue'
import Terms from '@/components/Common/Terms.vue'
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import { authenticate } from '@/views/globalHelpers'
// eslint-disable-next-line import/no-cycle
import axios from '@axios'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
// eslint-disable-next-line object-curly-newline
import { alphaValidator, emailValidator, minUserFullName, passwordValidator, required } from '@core/utils/validation'
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
  components: {
    AppBarI18n,
    GoogleAuth: () => import('./GoogleAuth.vue'),
    Terms,
    PrivacyTerms,
  },
  setup() {
    const termsDialogVisible = ref(false)
    const privacyDialogVisible = ref(false)

    // Template Refs
    const registerForm = ref(null)

    const isPasswordVisible = ref(false)
    const username = ref()
    const email = ref()
    const password = ref()
    const errorMessages = ref([])
    const accountExist = ref(false)
    const termsAgree = ref(false)
    const termsMsg = ref(false)
    const showTooltip = ref(false)

    const keepTooltipOpen = () => {
      if (password.value.length > 0) {
        showTooltip.value = true
      }
    }

    // Track start of registration
    Vue.prototype.$gtag.event('Start_Registration', {
      event_category: 'Registration',
      event_label: 'start register',
    })

    const handleFormSubmit = () => {
      if (!termsAgree.value) {
        termsMsg.value = true
      }
      const isFormValid = registerForm.value.validate() && termsAgree.value

      if (!isFormValid) return

      authenticate(
        axios.post(`/auth/register/${i18n.locale}`, {
          fullName: username.value,
          email: email.value,
          password: password.value,
        }),
        err => {
          if (err === 'user_exists') {
            accountExist.value = true
          } else {
            // Track successful registration
            Vue.prototype.$gtag.event('Complete_Registration', {
              event_category: 'Registration',
              event_label: 'Success',
            })
          }

          errorMessages.value = err
        },
      )
    }

    return {
      isPasswordVisible,
      username,
      email,
      password,
      errorMessages,
      handleFormSubmit,
      accountExist,
      termsDialogVisible,
      privacyDialogVisible,
      termsAgree,
      termsMsg,
      showTooltip,
      keepTooltipOpen,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
        alphaValidator,
        minUserFullName,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      registerForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
